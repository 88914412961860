import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex, GridItem, SimpleGrid } from '@chakra-ui/react'
import { useFormik } from 'formik'
import React from 'react'
import StoreApi from 'src/api/store'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { Input } from 'src/components/Form/Input'
import { MultiSelect } from 'src/components/Form/MultiSelect'
import { useAppContext } from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IOrderFilter, initialOrderFilter } from 'src/types/Order'
import { PaginationResponse } from 'src/types/Pagination'
import { IStore } from 'src/types/User'

interface OrderFilterProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (filter: IOrderFilter) => void
}

const OrderFilter = ({ isOpen, onClose, onSubmit }: OrderFilterProps) => {
  const { dispatch } = useAppContext()
  const [grocerList, setGrocerList] = React.useState<IStore[]>([])

  const { errors, values, handleBlur, handleChange, resetForm, handleSubmit } = useFormik({
    initialValues: initialOrderFilter,
    onSubmit,
  })

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    StoreApi.get({ limit: 1000, skip: 0 })
      .then((res: PaginationResponse<IStore>) => {
        setGrocerList(res.data ?? [])
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch])

  return (
    <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth="1px">Filter</DrawerHeader>
        <DrawerBody pt="8">
          <form onSubmit={handleSubmit}>
            <SimpleGrid spacing={['6', '8']} w="100%">
              <GridItem>
                <Input name="code" label="Code" error={errors.code} onBlur={handleBlur} onChange={handleChange} value={values.code} />
              </GridItem>
              <GridItem>
                <Input name="customer" label="Customer" error={errors.customer} onBlur={handleBlur} onChange={handleChange} value={values.customer} />
              </GridItem>
              <GridItem>
                <MultiSelect
                  label="Stores"
                  name="grocerList"
                  error={errors.grocerList}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.grocerList || []}
                  options={grocerList.map((grocer: IStore) => ({
                    value: grocer._id,
                    label: `${grocer.businessName} - ${grocer?.suburb}`,
                  }))}
                />
              </GridItem>
              <GridItem>
                <Input
                  type="date"
                  name="dateFrom"
                  label="Date from"
                  error={errors.dateFrom}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.dateFrom}
                />
              </GridItem>
              <GridItem>
                <Input
                  type="date"
                  name="dateTo"
                  label="Date to"
                  error={errors.dateTo}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.dateTo}
                />
              </GridItem>
              <GridItem>
                <Flex gap="4" align="flex-end" w="100%">
                  <PrimaryButton variant="outline" onClick={() => resetForm()}>
                    Reset
                  </PrimaryButton>
                  <PrimaryButton type="submit">Apply</PrimaryButton>
                </Flex>
              </GridItem>
            </SimpleGrid>
          </form>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default OrderFilter
