import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import { AiOutlineNotification, AiOutlineShoppingCart } from 'react-icons/ai'
import { BiCog, BiDonateHeart } from "react-icons/bi"
import { BsPatchQuestion } from 'react-icons/bs'
import { FiUsers } from 'react-icons/fi'
import { GrUserManager } from 'react-icons/gr'
import { HiOutlineDevicePhoneMobile } from 'react-icons/hi2'
import { IoMegaphoneOutline, IoShirtOutline, IoStorefrontOutline, IoTicketOutline } from 'react-icons/io5'
import { LiaMoneyBillWaveAltSolid } from "react-icons/lia"
import { MdOutlineBusinessCenter, MdOutlineDashboard, MdOutlinePhonelinkRing, MdOutlineReviews, MdOutlineSettingsSuggest } from 'react-icons/md'
import { RiAdvertisementLine } from 'react-icons/ri'
import { VscReport } from 'react-icons/vsc'
import { NavLink } from './NavLink'
import { NavSection } from './NavSection'

export function SidebarNav() {
  return (
    <Box>
      <NavLink icon={MdOutlineDashboard} href="/app/dashboard">
        Dashboard
      </NavLink>
      <Stack spacing="0" align="flex-start" mt="4">
        <NavSection title="CUSTOMERS">
          <NavLink icon={FiUsers} href="/app/customers">
            Customers
          </NavLink>
          <NavLink icon={AiOutlineShoppingCart} href="/app/orders">
            Orders
          </NavLink>
          <NavLink icon={AiOutlineNotification} href="/app/broadcasting">
            Push Notifications
          </NavLink>
        </NavSection>
        <NavSection title="STORES">
          <NavLink icon={IoStorefrontOutline} href="/app/stores">
            Stores
          </NavLink>
          <NavLink icon={MdOutlineBusinessCenter} href="/app/business-owner">
            Store Owners
          </NavLink>
          <NavLink icon={MdOutlineSettingsSuggest} href="/app/business-suggestion">
            Store Suggested By Users
          </NavLink>
          <NavLink icon={GrUserManager} href="/app/partners">
            Partners
          </NavLink>
          <NavLink icon={IoMegaphoneOutline} href="/app/ambassadors">
            Ambassadors
          </NavLink>
          <NavLink icon={BiDonateHeart} href="/app/beneficiaries">
            Beneficiaries
          </NavLink>
          <NavLink icon={LiaMoneyBillWaveAltSolid} href="/app/pending-payments">
            Pending Payments
          </NavLink>
        </NavSection>

        <NavSection title="GENERAL SETTINGS">
          <NavLink icon={HiOutlineDevicePhoneMobile} href="/app/app-version">
            App Versions
          </NavLink>
          <NavLink icon={RiAdvertisementLine} href="/app/promotional-banner">
            Promotional Banners
          </NavLink>
          <NavLink icon={IoMegaphoneOutline} href="/app/promotions">
            Promotions
          </NavLink>
          <NavLink icon={MdOutlineSettingsSuggest} href="/app/suggestion-reason">
            Suggestion Reason
          </NavLink>
          <NavLink icon={BsPatchQuestion} href="/app/faq">
            FAQ
          </NavLink>
          <NavLink icon={BsPatchQuestion} href="/app/business-faq">
            Business FAQ
          </NavLink>
          <NavLink icon={MdOutlinePhonelinkRing} href="/app/app-links">
            App Links
          </NavLink>
          <NavLink icon={IoShirtOutline} href="/app/merchandise">
            Merchandise
          </NavLink>
        </NavSection>

        <NavSection title="REVIEWS">
          <NavLink icon={MdOutlineSettingsSuggest} href="/app/review-reply-suggestion">
            Review Reply Suggestions
          </NavLink>
          <NavLink icon={MdOutlineReviews} href="/app/review-highlight">
            Review Highlights
          </NavLink>
          <NavLink icon={VscReport} href="/app/review-reported">
            Reviews Reported
          </NavLink>
        </NavSection>

        <NavSection title="VOUCHERS">
          <NavLink icon={IoTicketOutline} href="/app/voucher">
            Vouchers
          </NavLink>
          <NavLink icon={MdOutlineSettingsSuggest} href="/app/voucher-amount-suggestion">
            Voucher Suggestions
          </NavLink>
        </NavSection>

        <NavSection title="REPORTS">
          <NavLink icon={IoStorefrontOutline} href="/app/store-report">
            Store
          </NavLink>
          <NavLink icon={FiUsers} href="/app/user-report">
            Customer
          </NavLink>
        </NavSection>

        <NavLink icon={BiCog} href="/app/settings">
          Settings
        </NavLink>

        <Flex direction="column">
          <Text mt="2" color="gray.500">
            v2.2.0
          </Text>
        </Flex>
      </Stack>
    </Box>
  )
}
