import { useFormik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import CharityApi from 'src/api/charity'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { ICharity, charityInitialValue } from 'src/types/Charity'
import validationSchema from 'src/views/Charity/validationSchema'
import CharityForm from './CharityForm'

const CreateCharity: React.FC = () => {
    const pageName: string = 'Create Beneficiary'
    const navigate = useNavigate()

    const { dispatch } = React.useContext(AppContext)

    const onSubmit = (values: ICharity) => {
        dispatch({ type: Types.Loading, payload: { loading: true } })
        CharityApi.create({
            name: values.name,
            description: values.description,
            pictureFile: values.pictureFile,
            websiteUrl: values.websiteUrl,
            abn: values.abn,
            legalName: values.legalName,
            contactName: values.contactName,
            phoneNumber: values.phoneNumber,
            stripeBankAccountToken: values.stripeBankAccountToken,
            addressPlaceId: values.addressPlaceId,
            isFoodyBagCharity: values.isFoodyBagCharity,
            isAllowedToReceiveDonations: values.isAllowedToReceiveDonations,
            isAllowedToReceiveBags: values.isAllowedToReceiveBags,
        } as ICharity)
            .then((result) => {
                if (result.success) {
                    AlertSuccess('Beneficiary saved successfully!')
                    navigate('/app/beneficiaries')
                }
            })
            .catch((err: Error) => console.log(err))
            .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
    }

    const formik = useFormik({
        initialValues: charityInitialValue,
        onSubmit,
        validationSchema,
    })

    return (
        <Page title={pageName}>
            <form onSubmit={formik.handleSubmit}>
                <Toolbar title={pageName} showBack={true} buttons={<PrimaryButton type="submit">Save</PrimaryButton>} />
                <CharityForm formik={formik} />
            </form>
        </Page>
    )
}

export default CreateCharity
