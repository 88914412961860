import { MultiSelectOptionProps } from './components/Form/MultiSelect'
import AppVersionOsEnum from './enum/AppVersionOsEnum'
import { enumKeys } from './helpers/enumHelper'

export const NUMBER_ONLY_REGEX = /^\d+(\.\d{0,2})?$/
export const OPERATING_SYSTEM_FOR_SELECT: MultiSelectOptionProps[] = enumKeys(AppVersionOsEnum).map((os: string) => ({
  label: AppVersionOsEnum[os],
  value: os,
}))

export const REFRESH_TOKEN_API = '/admin/auth/refresh-token'
export const DASHBOARD_FILTER_DATES = [
  { value: 'ALL_TIME', label: 'All Time' },
  { value: 'LAST_7_DAYS', label: 'Last 7 Days' },
  { value: 'LAST_30_DAYS', label: 'Last 30 Days' },
  { value: 'LAST_90_DAYS', label: 'Last 90 Days' },
  { value: 'LAST_365_DAYS', label: 'Last 365 Days' },
]

export const DASHBOARD_FILTER_OPTIONS = [
  { value: '', label: 'Select one' },
  { value: 'WA', label: 'WA' },
  { value: 'NSW', label: 'NSW' },
  { value: 'VIC', label: 'VIC' },
  { value: 'QLD', label: 'QLD' },
  { value: 'SA', label: 'SA' },
  { value: 'TAS', label: 'TAS' },
  { value: 'NT', label: 'NT' },
  { value: 'ACT', label: 'ACT' },
]

export const SOCIAL_MEDIA_IMAGES = {
  FACEBOOK: '/static/facebook-icon.svg',
  INSTAGRAM: '/static/instagram-icon.svg',
  YOUTUBE: '/static/youtube-icon.svg',
  TIKTOK: '/static/tiktok-icon.svg',
}