import config from 'src/config'
import ScreenEnum from 'src/enum/ScreenEnum'

export const capitalizeFirstLetter = (value: string | undefined) => {
  if (!value) return undefined
  try {
    return value.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  } catch (error) {
    console.log(error)
  }
}

export const generateRandomString = (length: number) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) result += characters.charAt(Math.floor(Math.random() * charactersLength))
  return result
}

export const getChipLabel = (selected: string[], itemList: any[]) => {
  let result: { key: string; value: string }[] = []
  selected.forEach((item: string) => {
    if (result.map((item: { key: string; value: string }) => item.key).indexOf(item) < 0) {
      const index: number = itemList.map((d: any) => (d.id ? d.id : d)).indexOf(item)
      if (index >= 0) result.push({ key: item, value: itemList[index].name ? itemList[index].name : itemList[index] })
    }
  })
  return result
}

export const generateRandomColor = (opacity: string = '1'): string => {
  const o = Math.round,
    r = Math.random,
    s = 255
  return `rgba(${o(r() * s)},${o(r() * s)},${o(r() * s)},${opacity})`
  // return Math.floor(Math.random() * 16777215).toString(16)
}

function hex(c: any) {
  let s = '0123456789abcdef'
  let i = parseInt(c)
  if (i === 0 || isNaN(c)) return '00'
  i = Math.round(Math.min(Math.max(0, i), 255))
  return s.charAt((i - (i % 16)) / 16) + s.charAt(i % 16)
}

/* Convert an RGB triplet to a hex string */
function convertToHex(rgb: any) {
  return hex(rgb[0]) + hex(rgb[1]) + hex(rgb[2])
}

/* Remove '#' in color hex string */
function trim(s: any) {
  return s.charAt(0) === '#' ? s.substring(1, 7) : s
}

/* Convert a hex string to an RGB triplet */
function convertToRGB(hex: any) {
  let color = []
  color[0] = parseInt(trim(hex).substring(0, 2), 16)
  color[1] = parseInt(trim(hex).substring(2, 4), 16)
  color[2] = parseInt(trim(hex).substring(4, 6), 16)
  return color
}

export const generateColorRange = (colorStart: string, colorEnd: string, colorCount: number) => {
  // The beginning of your gradient
  let start = convertToRGB(colorStart)

  // The end of your gradient
  let end = convertToRGB(colorEnd)

  // The number of colors to compute
  let len = colorCount

  //Alpha blending amount
  let alpha = 0.0

  let result = []

  for (let i = 0; i < len; i++) {
    let c = []
    alpha += 1.0 / len

    c[0] = start[0] * alpha + (1 - alpha) * end[0]
    c[1] = start[1] * alpha + (1 - alpha) * end[1]
    c[2] = start[2] * alpha + (1 - alpha) * end[2]

    result.push(convertToHex(c))
  }

  return result
}

export const generateDeepLink = (screen: ScreenEnum, id?: string) => {
  const params = encodeURIComponent(`?screen=${screen}${id ? `&id=${id}` : ''}`)
  return `${config.deepLink}${params}`
}

export const formatNumberToString = (value: number, options: Intl.NumberFormatOptions) => {
  return new Intl.NumberFormat('en-AU', options).format(value)
}

export const formatNumberToCurrency = (value: number, options?: Intl.NumberFormatOptions) => {
  let formatOptions: Intl.NumberFormatOptions = options ? { style: 'currency', currency: 'AUD', ...options } : { style: 'currency', currency: 'AUD' }
  return new Intl.NumberFormat('en-AU', formatOptions).format(value)
}

export const formatDateToString = (value: Date | string, options?: Intl.DateTimeFormatOptions) => {
  const dateValue = typeof value === 'string' ? new Date(value) : value
  return new Intl.DateTimeFormat('en-AU', { day: '2-digit', month: 'long', year: 'numeric', ...options }).format(dateValue)
}

export const getFileNameFromFileUrl = (url: string) => {
  if (!url) return ''
  return url.split('/').pop()
}

export const validateVideoUrl = (url: string): boolean => {
  // support Youtube
  const youtubePattern = new RegExp('^(https?://)?(www.)?(youtube.com|youtu.?be)/.+$')
  // support Vimeo
  const vimeoPattern = new RegExp('^(https?://)?(www.)?(vimeo.com)/.+$')

  return youtubePattern.test(url) || vimeoPattern.test(url)
}